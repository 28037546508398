import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useSelector } from "react-redux";

import { BasicForm } from "@components/shared";
import { statusOptions } from "./status";
import countryOptions from "@util/countryOptions";
import { phoneValidation } from "@util/validation/phone";

import { getRoleInfo } from "@util/roleInfo";

export default function PracticeForm({
  id,
  siteId,
  name,
  streetAddress1,
  streetAddress2,
  streetAddress3,
  city,
  stateProvence,
  postalCode,
  preApprovalRequired,
  country,
  status,
  onSuccess,
}) {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const roleInfo = getRoleInfo(useSelector((state) => state));

  function onSubmit(formData) {
    if (id) return onUpdate(formData);

    return onCreate(formData);
  }

  function onCreate(formData) {
    const postData = {
      ...formData,
      stats: 1,
    };

    setLoading(true);
    setError(null);

    axios
      .post(`/practices/`, postData)
      .then(({ data }) => {
        createPhone(data.response[0].id, formData.phone);
        return data.response[0].id;
      })
      .then((newId) => {
        setLoading(false);
        toast.success("Trust Created!");
        onSuccess(newId);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  function createPhone(id, phoneNumber) {
    const req = {
      practice_id: id,
      number: phoneNumber,
    };

    axios.post(`/phones/`, req);
  }

  function onUpdate(formData) {
    const postData = {
      ...formData,
      practice_id: id,
    };

    setLoading(true);
    setError(null);

    axios
      .put(`/practices/${id}/`, postData)
      .then(() => setLoading(false))
      .then(() => toast.success("Trust Updated!"))
      .then(() => onSuccess())
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.ui_message);
      });
  }

  const fields = [
    {
      name: "site_ident",
      initialValue: siteId,
      label: "Site ID (3 Letters)",
      required: true,
      schema: () => Yup.string().min(2, "Too Short!").max(4, "Too Long!"),
    },
    {
      name: "name",
      initialValue: name,
      label: "Name",
      required: true,
      schema: () =>
        Yup.string()
          .min(2, "Too Short!")
          .max(255, "Too Long!")
          .required("Required"),
    },
    {
      label: "Street Address Line 1",
      initialValue: streetAddress1,
      name: "street_address_1",
      required: true,
      schema: () =>
        Yup.string()
          .min(2, "Too Short!")
          .max(120, "Too Long!")
          .required("Required"),
    },
    {
      label: "Street Address Line 2",
      initialValue: streetAddress2,
      name: "street_address_2",
      required: false,
      schema: () => Yup.string().min(2, "Too Short!").max(120, "Too Long!"),
    },
    {
      label: "Street Address Line 3",
      initialValue: streetAddress3,
      name: "street_address_3",
      required: false,
      schema: () => Yup.string().min(2, "Too Short!").max(120, "Too Long!"),
    },
    {
      grouped: true,
      fields: [
        {
          label: "City/Locality",
          initialValue: city,
          name: "city",
          required: true,
          schema: () =>
            Yup.string()
              .min(2, "Too Short!")
              .max(60, "Too Long!")
              .required("Required"),
        },
        {
          label: "State/Provence",
          initialValue: stateProvence,
          name: "state_provence",
          required: true,
          schema: () =>
            Yup.string()
              .min(2, "Too Short!")
              .max(50, "Too Long!")
              .required("Required"),
        },
        {
          label: "Postal Code",
          initialValue: postalCode,
          name: "postal_code",
          required: true,
          schema: () =>
            Yup.string()
              .min(2, "Too Short!")
              .max(12, "Too Long!")
              .required("Required"),
        },
      ].map((m) => ({
        ...m,
        readOnly: id && roleInfo.admin_research_fellow ? true : false,
      })),
    },
    {
      label: "Country",
      initialValue: country,
      name: "country",
      required: true,
      select: true,
      options: countryOptions,
      schema: () =>
        Yup.string()
          .min(2, "Too Short!")
          .max(55, "Too Long!")
          .required("Required"),
    },
    {
      name: "phone",
      label: "Main Phone",
      initialValue: "",
      required: true,
      schema: () =>
        Yup.string()
          .matches(phoneValidation, "Not a valid phone")
          .required("Required"),
    },
    {
      name: "pre_approval_required",
      label: "Patient Pre Approval Required",
      initialValue: preApprovalRequired,
      schema: () => null,
      radio: true,
    },
    {
      name: "status",
      label: "Status",
      initialValue: status,
      select: true,
      options: statusOptions,
      required: true,
      schema: () => Yup.number().nullable().required("Required"),
    },
  ]
    .filter((f) => (id ? !["phone"].includes(f.name) : true))
    .filter((f) =>
      !id ? !["status", "pre_approval_required"].includes(f.name) : true
    )
    .map((m) => ({
      ...m,
      readOnly: id && roleInfo.admin_research_fellow ? true : false,
    }));

  return (
    <div>
      <BasicForm
        error={error}
        fields={fields}
        loading={isLoading}
        onSubmit={onSubmit}
        showButton={!id ? true : !roleInfo.admin_research_fellow ? true : false}
      />
    </div>
  );
}

PracticeForm.defaultProps = {
  city: "",
  country: "",
  id: null,
  mainPhone: "",
  name: "",
  postalCode: "",
  preApprovalRequired: false,
  siteId: "",
  stateProvence: "",
  status: 1,
  streetAddress1: "",
  streetAddress2: "",
  streetAddress3: "",
  onSuccess: () => alert("On Success"),
};
