import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

import { BasicGrid, Card, Button, EmptyList } from "@components/shared";

import { getRoleInfo } from "@util/roleInfo";

export default function UserPracticeManagement({
  userId,
  userPractices,
  onUpdate,
}) {
  const [practices, setPractices] = useState([]);
  const [loading, setLoading] = useState(false);

  const userPracticeIds = userPractices.map((m) => m.practice_id);
  const roleInfo = getRoleInfo(useSelector((state) => state));

  useEffect(() => {
    fetchPractices();
  }, []);

  function fetchPractices() {
    const req = {
      filtered: [],
      page: 0,
      page_size: 500,
      sorted: [],
      status: 1,
    };

    axios
      .post(`/retrieve-practices/`, req)
      .then(({ data }) => {
        setPractices(
          data.response[0].data.sort((a, b) => (a.name - b.name ? 1 : -1))
        );
      })
      .catch((err) => {
        setLoading(false);
        setPractices([]);
      });
  }

  const addedPractices = practices.filter((f) =>
    userPracticeIds.includes(f.id)
  );
  const availablePractices = practices.filter(
    (f) => !userPracticeIds.includes(f.id)
  );

  return (
    <div>
      <h2>Added Practices</h2>
      {addedPractices.length > 0 ? (
        <React.Fragment>
          <BasicGrid columns={2}>
            {addedPractices.map((p) => (
              <PracticeItem
                key={p.id}
                name={p.name}
                id={p.id}
                userId={userId}
                joinId={
                  userPracticeIds.includes(p.id)
                    ? userPractices.find((f) => f.practice_id === p.id).id
                    : null
                }
                fetchData={onUpdate}
              />
            ))}
          </BasicGrid>
        </React.Fragment>
      ) : (
        <EmptyList text="No trusts added" />
      )}
      {!roleInfo.admin_research_fellow && (
        <React.Fragment>
          <h2>Available Practices</h2>
          {availablePractices.length > 0 ? (
            <React.Fragment>
              <BasicGrid columns={2}>
                {availablePractices.map((p) => (
                  <PracticeItem
                    key={p.id}
                    name={p.name}
                    id={p.id}
                    userId={userId}
                    fetchData={onUpdate}
                  />
                ))}
              </BasicGrid>
            </React.Fragment>
          ) : (
            <EmptyList text="No trusts available" />
          )}
        </React.Fragment>
      )}
    </div>
  );
}

UserPracticeManagement.defaultProps = {
  userPractices: [],
};

const PracticeItem = ({ id, joinId, userId, name, active, fetchData }) => {
  const [loading, setLoading] = useState(false);

  const roleInfo = getRoleInfo(useSelector((state) => state));

  function onClick() {
    setLoading(true);

    if (joinId) {
      return removePractice();
    }

    const req = {
      practice_id: id,
      user_id: userId,
    };

    axios
      .post(`/users/${userId}/practices/`, req)
      .then(() => {
        fetchData();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  function removePractice() {
    const req = {
      id: joinId,
      entity: "UserPractice",
    };

    axios
      .post(`/option-status/`, req)
      .then(() => {
        fetchData();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  return (
    <Card.Wrapper>
      <Card.Meta
        title={name}
        hasActions={userId && !roleInfo.admin_research_fellow ? true : false}
      />
      {userId && !roleInfo.admin_research_fellow && (
        <Card.Actions>
          <Button.Basic
            white={joinId ? false : true}
            primary={joinId ? true : false}
            text={joinId ? "Added" : "Click to add"}
            onClick={onClick}
            loading={loading}
            disabled={loading}
          />
        </Card.Actions>
      )}
    </Card.Wrapper>
  );
};

PracticeItem.defaultProps = {
  joinId: null,
  id: null,
  name: "",
  userId: null,
};
