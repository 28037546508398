import React, { useState } from "react";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import axios from "axios";

import { BasicForm } from "@components/shared";
import { phoneValidation } from "@util/validation/phone";

import { statusOptions, varietyOptions } from "./status";

function AdminForm({
  dispatch,
  email,
  firstName,
  id,
  isProfile,
  lastName,
  loggedInAdminId,
  mfa,
  onSuccess,
  phone,
  status,
  title,
  variety,
}) {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  function onSubmit(postData) {
    setLoading(true);
    setError(null);

    if (id) {
      return onUpdate(postData);
    }

    onCreate(postData);
  }

  function onUpdate(formData) {
    const req = { ...formData };

    axios
      .put(`/admins/${id}/`, {
        ...formData,
        admin_id: id,
      })
      .then(({ data }) => {
        setLoading(false);
      })
      .then(() => {
        toast.success("Admin Updated!");
      })
      .then(() => {
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  function onCreate(formData) {
    axios
      .post(`/admins/`, formData)
      // .then(({ data }) => createPhone(data.response[0].id, formData.phone))
      .then(() => {
        setLoading(false);
      })
      .then(() => {
        toast.success("Admin Created!");
      })
      .then(() => onSuccess())
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  function createPhone(adminId, phoneNumber) {
    const req = {
      admin_id: adminId,
      number: phoneNumber,
    };

    axios.post(`/phones/`, req);
  }

  const fields = [
    {
      grouped: true,
      fields: [
        {
          name: "first_name",
          label: "First Name",
          initialValue: firstName,
          required: true,
          schema: () => Yup.string().required("Required"),
        },
        {
          name: "last_name",
          label: "Last Name",
          initialValue: lastName,
          required: true,
          schema: () => Yup.string().required("Required"),
        },
      ],
    },
    {
      name: "email",
      label: "Login Email",
      initialValue: email,
      required: true,
      schema: () =>
        Yup.string().email("Not a valid email").required("Required"),
    },
    // {
    //   name: "phone",
    //   label: "Phone",
    //   initialValue: phone,
    //   required: true,
    //   schema: () =>
    //     Yup.string()
    //       .matches(phoneValidation, "Not a valid phone")
    //       .required("Required"),
    // },
    {
      name: "title",
      label: "Title",
      initialValue: title,
      required: false,
      schema: () => Yup.string(),
    },
    {
      name: "status",
      label: "Status",
      initialValue: status,
      select: true,
      options: statusOptions,
      required: true,
      schema: () => Yup.number().nullable().required("Required"),
    },
    {
      name: "variety",
      label: "Variety",
      initialValue: variety,
      select: true,
      options: varietyOptions,
      required: true,
      schema: () => Yup.number().nullable().required("Required"),
    },
    {
      name: "mfa",
      label: "Multi-Factor Authentication",
      initialValue: mfa,
      radio: true,
      required: false,
      schema: () => null,
    },
  ]
    .filter((f) => (!isProfile ? !["mfa"].includes(f.name) : true))
    .filter((f) =>
      !id ? !["status"].includes(f.name) : !["phone"].includes(f.name)
    )
    .filter((f) =>
      isProfile || loggedInAdminId == id
        ? !["status", "variety"].includes(f.name)
        : true
    );

  return (
    <BasicForm
      buttonText="Save"
      fields={fields}
      error={error}
      loading={isLoading}
      onSubmit={onSubmit}
    />
  );
}

AdminForm.defaultProps = {
  email: "",
  firstName: "",
  id: null,
  isProfile: false,
  lastName: "",
  phone: "",
  status,
  title: "",
  variety: null,
};

function mapStateToProps(state) {
  return {
    loggedInAdminId: state.admin.id,
  };
}

export default connect(mapStateToProps)(AdminForm);
